<template>
  <v-card flat class="xmx-auto xmax-w-[1200px]">
    <v-card-text>
      <div class="xp-2 xrounded-md xh-full">
        <div class="xflex xpy-[1.5em] xjustify-between xitems-center">
          <span class="xfont-bold xtext-[16px]">Slack ID</span>
          <span class="xfont-bold xtext-[17px]">{{ workspace.id }}</span>
        </div>
        <v-divider />
        <div class="xflex xpy-[1.5em] xjustify-between xitems-center">
          <span class="xfont-bold xtext-[16px]">Slack Domain</span>
          <span class="xfont-bold xtext-[17px]">{{ workspace.domain }}</span>
        </div>
        <v-divider />
        <div class="xflex xpy-[1.5em] xjustify-between xitems-center">
          <span class="xfont-bold xtext-[16px]">Installed Date</span>
          <span class="xfont-bold xtext-[17px]">
            {{ workspace.created_at | format("LLL") }}
          </span>
        </div>
        <v-divider />
        <div class="xflex xpy-[1.5em] xjustify-between xitems-center">
          <span class="xfont-bold xtext-[16px]">Subscription Status</span>
          <span class="xfont-bold xtext-[17px]">{{ workspace.status }}</span>
        </div>
        <v-divider />
        <div class="xflex xpy-[1.5em] xjustify-between xitems-center">
          <span class="xfont-bold xtext-[16px]">Timezone</span>
          <span class="xfont-bold xtext-[17px]">{{ workspace.tz }}</span>
        </div>
        <v-divider />
        <div
          class="xflex xpy-[1.5em] xjustify-between xitems-center"
          v-if="!workspace.is_trashed"
        >
          <span class="xfont-bold xtext-[16px]">Delete Team?</span>
          <btn-tooltip
            @click="deleteTeam"
            tip="Delete this workspace"
            color="error"
          >
            Delete
          </btn-tooltip>
        </div>
        <div class="xflex xpy-[1.5em] xjustify-between xitems-center" v-else>
          <span class="xfont-bold xtext-[16px]">Restore Team?</span>
          <btn-tooltip
            @click="restoreTeam"
            tip="Restore this workspace"
            color="primary"
            outlined
          >
            Restore
          </btn-tooltip>
        </div>
        <v-divider />
        <div
          class="xflex xpy-[1.5em] xjustify-between xitems-center"
          v-if="!workspace.is_trashed && workspace.status != 'EXEMPTED'"
        >
          <span class="xfont-bold xtext-[16px]">
            Exempt Team From Subscription?
          </span>
          <btn-tooltip
            @click="exemptTeam"
            tip="Exempt this workspace for subscription"
            color="primary"
          >
            Exempt
          </btn-tooltip>
        </div>
        <div
          class="xflex xpy-[1.5em] xjustify-between xitems-center"
          v-if="!workspace.is_trashed && workspace.status == 'EXEMPTED'"
        >
          <span class="xfont-bold xtext-[16px]">
            Remove from exemption for subscriptions?
          </span>
          <btn-tooltip
            @click="removeExemptTeam"
            tip="Remove this workspace from subscription exemption"
            color="primary"
          >
            Remove Exemption
          </btn-tooltip>
        </div>
        <v-divider />
      </div>
      <!-- <pre>{{ workspace }}</pre> -->
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("workspace", ["workspace"]),
  },
  data() {
    return {
      submitting: false,
    };
  },
  methods: {
    ...mapActions("workspace", ["fetchWorkspaceInfo"]),
    deleteTeam() {
      this.appConfirmation(
        "Are you sure to delete this team? <br/>This will not delete from the database, only the access of users to this team.",
        () => {
          this.submitting = true;
          this.$axios
            .delete(`admin/workspaces/${this.workspace.id}/delete`)
            .then(({ data }) => {
              this.fetchWorkspaceInfo(this.workspace.id);
              this.appToast("Successfully deleted", "success");
            })
            .finally(() => (this.submitting = false));
        },
        null
      );
    },
    restoreTeam() {
      this.appConfirmation(
        "Are you sure to restore this team?",
        () => {
          this.submitting = true;
          this.$axios
            .put(`admin/workspaces/${this.workspace.id}/restore`)
            .then(({ data }) => {
              this.fetchWorkspaceInfo(this.workspace.id);
              this.appToast("Successfully restored", "success");
            })
            .finally(() => (this.submitting = false));
        },
        null
      );
    },
    exemptTeam() {
      this.appConfirmation(
        "Are you sure to exempt this team from paying?<br/>If team is subscribed it will be cancelled and will turn subscription status to EXEMPTED",
        () => {
          this.submitting = true;
          this.$axios
            .put(`admin/workspaces/${this.workspace.id}/exempt`)
            .then(({ data }) => {
              this.fetchWorkspaceInfo(this.workspace.id);
              this.appToast(
                "Successfully added to exempted for subscriptions",
                "success"
              );
            })
            .finally(() => (this.submitting = false));
        },
        null
      );
    },
    removeExemptTeam() {
      this.appConfirmation(
        "Are you sure to remove from exemption for paying this team?",
        () => {
          this.submitting = true;
          this.$axios
            .put(`admin/workspaces/${this.workspace.id}/remove-exempt`)
            .then(({ data }) => {
              this.fetchWorkspaceInfo(this.workspace.id);
              this.appToast(
                "Successfully removed from exempted for subscriptions",
                "success"
              );
            })
            .finally(() => (this.submitting = false));
        },
        null
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
